import { render, staticRenderFns } from "@/../../../templates/brand/components/templates/shop/GiftCredit.html?vue&type=template&id=07fd09e8&"
import script from "./GiftCredit.vue?vue&type=script&lang=js&"
export * from "./GiftCredit.vue?vue&type=script&lang=js&"
import style0 from "@/../../../templates/brand/components/styles/shop/GiftCredit.css?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports